'use client'

import React from 'react'
import { useSports } from 'hooks'

import Sport, { SportSkeleton } from '../Sport/Sport'
import { LeagueSkeleton } from '../League/League'
import EmptyContent from '../EmptyContent/EmptyContent'
import Navbar, { NavbarSkeleton } from '../Navbar/Navbar'
import FilteredLeagues from '../FilteredLeagues/FilteredLeagues'
import UniqueEvents from '../UniqueEvents/UniqueEvents'


const Sports: React.FC = React.memo(() => {
  const { sports, loading } = useSports()

  if (loading) {
    return (
      <>
        <NavbarSkeleton />
        <SportSkeleton>
          <LeagueSkeleton />
        </SportSkeleton>
      </>
    )
  }

  if (!sports) {
    return <EmptyContent />
  }

  return (
    <Navbar>
      {
        sports.map((sport) => (
          <Sport key={sport.slug} sport={sport}>
            {
              sport.slug === 'unique' ? (
                <UniqueEvents leagues={sport.leagues} />
              ) : (
                <FilteredLeagues
                  sportSlug={sport.slug}
                  leagues={sport.leagues}
                />
              )
            }
          </Sport>
        ))
      }
    </Navbar>
  )
})

Sports.displayName = 'Sports'

export default Sports
