'use client'

import React, { useMemo, useState } from 'react'
import { useParams, useRouter } from 'next/navigation'
import { useTranslations, useLocale } from 'next-intl'
import { useLive, useNavigation } from '@azuro-org/sdk'
import { type NavigationQuery } from '@azuro-org/toolkit'
import cx from 'classnames'
import { constants } from 'helpers'

import { Icon, type IconName } from 'components/ui'
import { Href } from 'components/navigation'
import { Flag } from 'components/dataDisplay'

import Skeleton from './components/Skeleton/Skeleton'
import { useNavigationData } from './hooks/useNavigationData'


type LeagueProps = NavigationQuery['sports'][0]['countries'][0]['leagues'][0] & {
  url: string
  country: {
    name: string
    slug: string
  }
}

const League: React.FC<LeagueProps> = (props) => {
  const { url, name, country, games, slug } = props

  const { countrySlug, leagueSlug } = useParams()

  const isActive = Boolean(leagueSlug) && countrySlug === country.slug && slug === leagueSlug

  const rootClassName = cx(
    'flex items-center justify-between py-2.5 px-4',
    'transition-colors duration-200',
    {
      'text-grey-60 hover:text-white': !isActive,
      'text-white bg-opacity-10 bg-white': isActive,
    }
  )

  return (
    <Href to={url} className={rootClassName}>
      <div className="flex items-center overflow-hidden">
        <Flag className="mr-3 flex-none w-5 h-5" country={country.slug} />
        <div className="text-[14px] font-medium tracking-wide text-ellipsis whitespace-nowrap overflow-hidden">
          {name}
        </div>
      </div>
      <div className="text-[13px] text-grey-60 min-w-[24px] text-center">
        {games?.length || 0}
      </div>
    </Href>
  )
}

type Top = {
  slug: '/'
  name: string
  gamesCount?: number
}

type SportProps = (NavigationQuery['sports'][0] | Top) & {
  weight?: number
  slug: string // 添加这个来确保 slug 总是 string 类型
}

const Sport: React.FC<SportProps> = ({ slug, name, ...rest }) => {
  const gamesCount = 'gamesCount' in rest ? rest.gamesCount : undefined
  const countries = 'countries' in rest ? rest.countries : undefined

  const { sportSlug } = useParams()
  const router = useRouter()

  const isTop = slug === '/'
  const isActive = sportSlug === slug || (isTop && !sportSlug)
  const isUnique = slug === 'unique'

  const rootClassName = cx('p-px rounded-md overflow-hidden', {
    'bg-card-border-top': isActive,
  })
  const wrapperClassName = cx({ 'bg-bg-l1 rounded-md': isActive })
  const buttonClassName = cx(
    'group px-4 py-3 flex w-full items-center justify-between',
    'transition-colors duration-200',
    {
      'text-grey-60 hover:text-white': !isActive,
      'text-white': isActive,
    }
  )
  const iconClassName = cx('h-4 w-4', {
    'rotate-180': isActive,
  })
  const icon: IconName = isTop || isUnique ? 'interface/top' : `sport/${slug}` as IconName

  const leagues = useMemo(() => {
    if (!countries) {
      return
    }

    return countries.map(({ leagues, name, slug: countrySlug }) => {
      return leagues.map(league => ({
        url: `/${slug}/${countrySlug}/${league.slug}`,
        ...league,
        country: {
          name,
          slug: countrySlug,
        },
      }))
    }).flat()
  }, [ countries ])

  // 计算总游戏数，对所有项目（包括 Unique）使用相同逻辑
  const totalGamesCount = useMemo(() => {
    if (!countries) {
      return 0
    }

    return countries.reduce((total, country) =>
      total + country.leagues.reduce((acc, league) =>
        acc + (league.games?.length || 0), 0
      ), 0
    )
  }, [ countries ])

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()

    // 如果当前项目已激活，点击时导航到根路径
    if (isActive) {
      router.push('/')
    }
    else {
      // 否则导航到对应的sport路径
      router.push(`/${slug}`)
    }
  }

  return (
    <div className={rootClassName}>
      <div className={wrapperClassName}>
        <Href
          to={`/${slug}`}
          className={buttonClassName}
          onClick={handleClick}
        >
          <div className="flex items-center">
            <Icon
              className={
                cx(
                  'size-5 mr-3',
                  'opacity-80 group-hover:opacity-100 transition-opacity'
                )
              }
              name={icon}
            />
            <span className="text-[15px] font-medium tracking-wide">{name}</span>
          </div>
          {/* 修改显示逻辑，但保持计数一致 */}
          {
            Boolean(isTop || isUnique || !leagues?.length) ? (
              <div className="text-caption-12 min-w-4 text-center">
                {isTop ? gamesCount : totalGamesCount}
              </div>
            ) : (
              <Icon className={iconClassName} name="interface/chevron_down" />
            )
          }
        </Href>
        {/* Unique 项目不展开显示联赛列表 */}
        {
          Boolean(!isUnique && isActive && leagues) && (
            leagues?.map((league) => (
              <League key={`${league.country.slug}-${league.slug}`} {...league} />
            ))
          )
        }
      </div>
    </div>
  )
}

type NavigationProps = {
  className?: string
}

const Navigation: React.FC<NavigationProps> = ({ className }) => {
  const [ isOthersExpanded, setIsOthersExpanded ] = useState(false)
  const tCompositions = useTranslations('compositions')

  const { activeSports, inactiveSports, loading, allTopGames } = useNavigationData()

  if (loading) {
    return <Skeleton className={className} />
  }

  return (
    <div className={cx(className, 'bg-[#121212]', 'space-y-1')}>
      <p className="text-caption-13 font-semibold py-2 px-4 mb-2">
        {tCompositions('Navigation.title')}
      </p>

      <Sport
        slug="/"
        name={tCompositions('Navigation.top')}
        gamesCount={allTopGames}
      />

      {
        activeSports.map(sport => (
          <Sport key={sport.slug} {...sport} />
        ))
      }

      <div className="mt-2">
        <button
          onClick={() => setIsOthersExpanded(prev => !prev)}
          className="w-full flex items-center justify-between px-4 py-2 text-caption-13 text-grey-60 hover:text-grey-90"
        >
          <span>{tCompositions('Navigation.noActiveGames')}</span>
          <Icon
            className={
              cx('size-4 transition-transform', {
                'rotate-180': isOthersExpanded,
              })
            }
            name="interface/chevron_down"
          />
        </button>

        {
          isOthersExpanded && (
            <div className="space-y-1">
              {
                inactiveSports.map(sport => (
                  <Sport key={sport.slug} {...sport} />
                ))
              }
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Navigation
