import { useTranslations } from 'next-intl'
import { translationUtils } from './utils'

/**
 * 联赛翻译 Hook
 *
 * 用途:
 * - 用于获取联赛名称的多语言翻译
 * - 主要在展示联赛名称的组件中使用，如 TopEvents 组件
 *
 * 调用示例:
 * const { getLeagueTranslation } = useLeagueTranslation()
 * const translatedName = getLeagueTranslation(
 *   leagueName,    // 原始联赛名称
 *   leagueSlug,    // 联赛 slug
 *   sportId,       // 体育项目 ID
 *   countrySlug    // 国家 slug
 * )
 *
 * 注意事项:
 * 1. 需要确保 next-intl 的 messages 中包含 leagues 命名空间的翻译
 * 2. 如果找不到对应翻译，会降级使用原始联赛名称
 * 3. 翻译键的格式为: sportId_countrySlug_leagueSlug
 */
export const useLeagueTranslation = () => {
  const tLeagues = useTranslations('leagues')

  const getLeagueTranslation = (
    leagueName: string,
    leagueSlug: string,
    sportId: string,
    countrySlug: string
  ) => {
    try {
      const normalizedLeagueName = translationUtils.normalizeKey(leagueSlug)
      const translationKey = translationUtils.createTranslationKey(sportId, countrySlug, normalizedLeagueName)
      const translation = (tLeagues.raw as (key: string) => string)(translationKey)

      return (translation && translation !== translationKey)
        ? translation
        : (tLeagues as (key: string) => string)(leagueName)
    }
    catch (error) {
      console.error('League translation error:', error)

      return (tLeagues as (key: string) => string)(leagueName)
    }
  }

  return { getLeagueTranslation }
}
