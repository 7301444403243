import { useMemo } from 'react'
import { useLive, useNavigation } from '@azuro-org/sdk'
import { type NavigationQuery } from '@azuro-org/toolkit'
import { useLeagueTranslation, useSportTranslation } from 'hooks'
import { constants } from 'helpers'


interface ProcessedSport extends Omit<NavigationQuery['sports'][0], 'name'> {
  name: string
}

export const useNavigationData = () => {
  const { getSportTranslation } = useSportTranslation()
  const { getLeagueTranslation } = useLeagueTranslation()

  const { isLive } = useLive()
  const { navigation: rawNavigation, loading } = useNavigation({
    withGameCount: true,
    isLive,
  })

  const navigation = useMemo(() => {
    if (!rawNavigation) {
      return
    }

    try {
      const processedSports: ProcessedSport[] = rawNavigation.map(sport => {
        // 体育项目翻译处理
        const translatedName = getSportTranslation(sport.name, sport.id)

        // 联赛翻译处理
        const translatedCountries = sport.countries.map(country => ({
          ...country,
          leagues: country.leagues.map(league => {
            const translatedLeagueName = getLeagueTranslation(
              league.name,
              league.slug || league.name,
              sport.id,
              country.slug || country.name
            )

            if (translatedLeagueName && translatedLeagueName !== league.name) {
              return { ...league, name: translatedLeagueName }
            }

            return league
          }),
        }))

        return {
          ...sport,
          name: translatedName,
          countries: translatedCountries,
        }
      })

      return processedSports.sort((a, b) => {
        const indexA = constants.sportsOrder.indexOf(a.slug)
        const indexB = constants.sportsOrder.indexOf(b.slug)

        if (indexA >= 0 && indexB >= 0) {
          return indexA - indexB
        }

        if (indexA >= 0) {
          return -1
        }

        if (indexB >= 0) {
          return 1
        }

        return a.name.localeCompare(b.name)
      })
    }
    catch (error) {
      console.error('Error processing navigation data:', error)

      return rawNavigation
    }
  }, [ rawNavigation, getSportTranslation, getLeagueTranslation ])

  const { activeSports, inactiveSports } = useMemo(() => {
    if (!navigation) {
      return { activeSports: [], inactiveSports: [] }
    }

    const active: ProcessedSport[] = []
    const inactive: ProcessedSport[] = []

    navigation.forEach(sport => {
      const hasGames = sport.countries.some(country =>
        country.leagues.some(league => (league.games?.length ?? 0) > 0)
      )

      if (hasGames) {
        active.push(sport)
      }
      else {
        inactive.push(sport)
      }
    })

    return { activeSports: active, inactiveSports: inactive }
  }, [ navigation ])

  const allTopGames = useMemo(() => {
    if (!navigation) {
      return
    }

    let result = 0
    Object.values(navigation).forEach(({ countries }) => {
      let gamesCount = 0
      countries.forEach(({ leagues }) => {
        gamesCount += leagues.reduce((acc, { games }) => acc + games!.length, 0)
      })
      result += Math.min(gamesCount, constants.topPageGamePerSportLimit)
    })

    return result
  }, [ navigation ])

  return {
    navigation,
    activeSports,
    inactiveSports,
    loading,
    allTopGames,
  }
}
