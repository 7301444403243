'use client'

import Error from 'compositions/Error/Error'


export default function ErrorPage() {

  return (
    <Error />
  )
}
