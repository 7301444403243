/**
 * 国家名称翻译 Hook
 *
 * @description
 * 用于获取国家名称的多语言翻译。基于 next-intl 实现，需要在 messages/{locale}/countries.json 中配置对应的翻译。
 *
 * @example
 * ```tsx
 * // 在组件中使用
 * const { getCountryTranslation } = useCountryTranslation()
 *
 * // 获取翻译，传入国家名称和slug
 * const translatedName = getCountryTranslation('England', 'england')
 * ```
 *
 * @remarks
 * - 需要确保 messages/{locale}/countries.json 中包含对应的国家翻译键值对
 * - 如果找不到翻译，会返回原始的国家名称并在控制台打印警告
 * - 建议同时传入 countryName 和 countrySlug 以便于后续扩展功能
 *
 * @returns {{ getCountryTranslation: (countryName: string, countrySlug: string) => string }}
 */
import { useTranslations } from 'next-intl'


export const useCountryTranslation = () => {
  const tCountries = useTranslations('countries')

  const getCountryTranslation = (countryName: string, countrySlug: string) => {
    try {
      // 直接使用 countryName 获取翻译
      return tCountries(countryName)
    }
    catch (error) {
      console.warn(`Translation not found for country: ${countryName}`, error)

      return countryName
    }
  }

  return { getCountryTranslation }
}
