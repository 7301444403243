'use client'
import cx from 'classnames'
import { forwardRef, type AnchorHTMLAttributes } from 'react'
// import { Message } from '@locmod/intl'
// import { useTranslations } from 'next-intl'
import { Link } from '@/i18n/routing'


export type HrefProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  className?: string
  href?: string
  to?: string
  toTab?: string
  mailto?: string
  message?: string // 修改类型为 string
  onClick?: (props: any) => void
}

const Href = forwardRef<HTMLAnchorElement, HrefProps>((props, ref) => {
  // const t = useTranslations('Href')

  const { children, className, href, to, toTab, mailto, message, ...rest } = props

  const rootClassName = cx(className, {
    'cursor-pointer': (to || toTab || mailto || href || rest.onClick) && !rest['aria-disabled'],
  })

  rest.tabIndex = rest.tabIndex || 0

  // const content = message ? t(message as any) : children
  const content = message || children // 直接使用 message 或 children

  if (href || to) {
    return (
      <Link
        ref={ref}
        href={href || to || '#'}
        className={rootClassName}
        rel="noopener noreferrer nofollow"
        {...rest}
      >
        {content}
      </Link>
    )
  }

  if (toTab) {
    return (
      <a
        ref={ref}
        className={rootClassName}
        href={toTab}
        target="_blank"
        rel="noopener noreferrer nofollow"
        {...rest}
      >
        {content}
      </a>
    )
  }

  if (mailto) {
    return (
      <a
        ref={ref}
        className={rootClassName}
        href={`mailto:${mailto}`}
        {...rest}
      >
        {content || mailto}
      </a>
    )
  }

  return (
    <Link
      ref={ref}
      href="#"
      className={rootClassName}
      {...rest}
    >
      {content}
    </Link>
  )
})

export default Href
