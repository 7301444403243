import { useTranslations } from 'next-intl'
import { useCallback } from 'react'
import { translationUtils } from './utils'


type TeamTranslation = {
  getTeamTranslation: (teamName: string, sportId: string) => string
}

/**
 * 用于获取和转换队伍名称的翻译 Hook
 *
 * @returns {TeamTranslation} 返回对象包含:
 * - getTeamTranslation: (teamName: string, sportId: string) => string
 *   用于获取队伍翻译的函数，接收队伍名称和体育项目ID作为参数
 *
 * @example
 * // 基础用法
 * const { getTeamTranslation } = useTeamTranslations()
 * const translatedName = getTeamTranslation('TeamName', '1')
 *
 * @注意事项
 * 1. 如果翻译失败会返回原始队名
 * 2. 建议在使用前确保 translations 文件中已包含相应的队伍翻译
 */
export const useTeamTranslations = (): TeamTranslation => {
  const tTeams = useTranslations('teams')

  const getTeamTranslation = useCallback((teamName: string, sportId: string): string => {
    if (!teamName || !sportId) {
      console.warn('Team translation: teamName and sportId are required')

      return teamName
    }

    try {
      const normalizedTeamName = translationUtils.normalizeKey(teamName)
      const sportTeams = tTeams.raw(sportId)

      if (!sportTeams) {
        console.warn(`Team translation: no translations found for sport ID ${sportId}`)

        return teamName
      }

      return sportTeams[normalizedTeamName] || teamName
    }
    catch (error) {
      console.error('Team translation error:', error instanceof Error ? error.message : error)

      return teamName
    }
  }, [ tTeams ])

  return { getTeamTranslation }
}
