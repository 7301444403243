'use client'

import '@glidejs/glide/dist/css/glide.core.min.css'
import Glide from '@glidejs/glide'
import React, { useEffect, useRef } from 'react'
import { useTranslations } from 'next-intl'
//import { useParams } from 'next/navigation'
import { useActiveMarkets, useGames } from '@azuro-org/sdk'
import { Game_OrderBy, type GamesQuery, GameStatus } from '@azuro-org/toolkit'
import cx from 'classnames'
import {
  useLeagueTranslation,
  useTeamTranslations,
  useCountryTranslation,
} from 'hooks'
import { useGameDateTime } from 'helpers/getters'

import { Icon, type IconName } from 'components/ui'
import { OpponentLogo } from 'components/dataDisplay'
import { Href } from 'components/navigation'
import OutcomeButton from 'compositions/OutcomeButton/OutcomeButton'

//import messages from './messages'


const CardSkeleton: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={cx('bone h-[12.125rem] w-full rounded-md', className)} />
  )
}

type CardProps = {
  game: GamesQuery['games'][0]
}

// 添加类型定义
type TranslationsType = ReturnType<typeof useTranslations>

const TeamNames: React.FC<{
  team1: string;
  team2: string;
  tTeams: TranslationsType;
}> = ({ team1, team2, tTeams }) => {
  const renderTeamNames = () => `${team1} vs ${team2}`

  try {
    const hasTranslation = (tTeams.raw as (key: string) => string)('gameFormat') !== 'games.gameFormat'

    return (
      <div className="mt-5 text-caption-13 font-semibold text-center text-ellipsis whitespace-nowrap overflow-hidden">
        {
          hasTranslation
            ? (tTeams as (key: string, values: { team1: string; team2: string }) => string)('gameFormat', { team1, team2 })
            : renderTeamNames()
        }
      </div>
    )
  }
  catch (error) {
    console.error('Game format translation error:', error)

    return (
      <div className="mt-5 text-caption-13 font-semibold text-center text-ellipsis whitespace-nowrap overflow-hidden">
        {renderTeamNames()}
      </div>
    )
  }
}

const CARD_STYLES = {
  wrapper: 'bg-card-border-bottom p-px rounded-md overflow-hidden',
  inner: 'p-4 bg-grey-10 rounded-md',
  header: 'flex items-center justify-center text-grey-60 text-caption-13 hover:underline',
  opponents: 'mt-3 flex items-center justify-between px-7',
  outcomes: 'mt-3 flex items-center space-x-2',
} as const

const Card: React.FC<CardProps> = ({ game }) => {
  // const tSports = useTranslations('sports')
  // const tLeagues = useTranslations('leagues')
  const { getLeagueTranslation } = useLeagueTranslation()
  const { getCountryTranslation } = useCountryTranslation()
  const { getTeamTranslation } = useTeamTranslations()

  const {
    sport: {
      sportId,
      slug: sportSlug,
    },
    league: {
      name: leagueName,
      slug: leagueSlug,
      country: {
        name: countryName,
        slug: countrySlug,
      },
    },
    gameId,
    participants,
    startsAt,
    title,
  } = game

  const { date, time } = useGameDateTime(+startsAt * 1000)

  const { markets, loading } = useActiveMarkets({
    gameId: game.gameId,
    gameStatus: GameStatus.Created,
  })

  // console.log('useActiveMarkets data:', { markets, loading })

  const marketsRow = markets?.[0]?.outcomeRows?.[0]

  const team1 = getTeamTranslation(participants[0].name, sportId)
  const team2 = getTeamTranslation(participants[1].name, sportId)

  const tTeams = useTranslations('teams')

  return (
    <div className={CARD_STYLES.wrapper}>
      <div className={CARD_STYLES.inner}>
        <Href to={`${sportSlug}/${countrySlug}/${leagueSlug}/${gameId}`} className={CARD_STYLES.header}>
          <Icon className="size-4 mr-2 flex-none" name={`sport/${sportSlug}` as IconName} />
          <span className="text-ellipsis whitespace-nowrap overflow-hidden">
            {getCountryTranslation(countryName, countrySlug)}
          </span>
          <div className="size-[2px] rounded-full bg-grey-20 mx-1" />
          <span className="text-ellipsis whitespace-nowrap overflow-hidden">
            {getLeagueTranslation(leagueName, leagueSlug, sportId, countrySlug)}
          </span>
        </Href>
        <div className={CARD_STYLES.opponents}>
          <OpponentLogo image={participants[0].image} size={48} />
          <div className="text-caption-12 text-center">
            <div className="text-grey-60">{date}</div>
            <div className="font-semibold mt-[2px]">{time}</div>
          </div>
          <OpponentLogo image={participants[1].image} size={48} />
        </div>
        <TeamNames team1={team1} team2={team2} tTeams={tTeams} />
        <div className={CARD_STYLES.outcomes}>
          {
            loading ? (
              <>
                <div className="bone w-full h-7 rounded-sm" />
                <div className="bone w-full h-7 rounded-sm" />
                <div className="bone w-full h-7 rounded-sm" />
              </>
            ) : (
              marketsRow.map(outcome => (
                <OutcomeButton key={outcome.outcomeId} outcome={outcome} />
              ))
            )
          }
        </div>
      </div>
    </div>
  )
}

const SLIDER_CONFIG = {
  gap: 8,
  perView: 3,
  startAt: 0,
  focusAt: 0,
  autoplay: 5000,
  bound: true,
  breakpoints: {
    802: {
      perView: 1.1,
    },
  },
} as const

const Events: React.FC = () => {
  const { games, loading } = useGames({
    filter: {
      limit: 9,
    },
    orderBy: Game_OrderBy.Turnover,
  })
  const containerRef = useRef<HTMLDivElement>(null)

  // console.log('useGames data:', { games, loading })

  useEffect(() => {
    if (!games?.length) {
      return
    }

    const slider = new Glide(containerRef.current, SLIDER_CONFIG)
    slider.mount()

    return () => slider?.destroy()
  }, [ games ])

  if (loading) {
    return (
      <div className="flex items-center justify-between mt-6 space-x-2">
        <CardSkeleton />
        <CardSkeleton className="mb:hidden" />
        <CardSkeleton className="mb:hidden" />
      </div>
    )
  }

  return (
    <div ref={containerRef} className="glide !static group mt-6">
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {
            games?.map((game, index) => (
              <li key={index} className="glide__slide overflow-hidden">
                <Card game={game} />
              </li>
            ))
          }
        </ul>
      </div>
      <div className="absolute top-6 right-6 flex items-center" data-glide-el="controls">
        <button className="w-8 h-6 flex items-center justify-center bg-bg-l0 rounded-tl-full rounded-tr-1 rounded-br-1 rounded-bl-full border border-grey-15 text-grey-60 hover:text-grey-90 transition" data-glide-dir="<">
          <Icon className="size-5" name="interface/chevron_left" />
        </button>
        <button className="w-8 h-6 flex items-center justify-center bg-bg-l0 rounded-tl-1 rounded-tr-full rounded-br-full rounded-bl-1 border border-grey-15 text-grey-60 hover:text-grey-90 transition ml-1" data-glide-dir=">">
          <Icon className="size-5" name="interface/chevron_right" />
        </button>
      </div>
    </div>
  )
}

const TopEvents: React.FC = () => {
  const t = useTranslations('compositions.TopEvents')

  return (
    <div className="relative pt-6">
      <div className="px-4">
        <div className="text-caption-13 text-grey-60 uppercase">
          {t('title')}
        </div>
        <h1 className="text-heading-h1 font-bold">
          <span className="text-brand-50">{t('top')}</span>
          <span className="ml-2">{t('events')}</span>
        </h1>
      </div>
      <Events />
    </div>
  )
}

export default TopEvents
