export default {
  myBets: {
    en: 'My bets',
  },
  disconnect: {
    en: 'Disconnect',
  },
  wave: {
    title: {
      en: 'Azuro Wave Points',
    },
    text: {
      en: 'Level: {level}',
    },
  },
}
