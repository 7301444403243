import { useTranslations } from 'next-intl'
import { translationUtils } from './utils'

/**
 * 体育项目翻译 Hook
 *
 * 使用场景:
 * 1. Navigation 组件中展示体育项目名称
 * 2. TopEvents 等需要显示体育项目信息的组件
 *
 * 调用示例:
 * const { getSportTranslation } = useSportTranslation()
 * const sportName = getSportTranslation(sport.name, sport.sportId)
 *
 * 注意事项:
 * 1. 优先使用 sportId 获取翻译，因为 ID 是稳定的标识符
 * 2. 如果 ID 翻译失败，会尝试使用 sportName 获取翻译
 * 3. 所有翻译都失败时返回原始 sportName
 * 4. 确保在 messages/ 下配置了对应的体育项目翻译
 */
export const useSportTranslation = () => {
  // 获取 sports 命名空间下的翻译函数
  const tSports = useTranslations('sports')

  const getSportTranslation = (sportName: string, sportId: string) => {
    try {
      // 对于数字ID的sport尝试获取翻译
      if (!isNaN(Number(sportId))) {
        const translation = (tSports.raw as (key: string) => string)(sportId)

        if (translation && translation !== sportId) {
          return translation
        }
      }

      // 如果没有找到翻译或发生错误，返回原始名称
      return (tSports as (key: string) => string)(sportName)
    }
    catch (error) {
      console.warn(`Translation not found for sport id: ${sportId}`, error)

      return sportName
    }
  }

  return { getSportTranslation }
}
