// src/i18n.ts
import { getRequestConfig } from 'next-intl/server'
import { notFound } from 'next/navigation'


export const locales = [ 'en', 'zh' ] as const
export type Locale = typeof locales[number];
export const defaultLocale = 'en' as const
export const localePrefix = 'always' as const

export const languageConfig: Record<Locale, { name: string; nativeName: string }> = {
  en: { name: 'English', nativeName: 'English' },
  zh: { name: 'Chinese', nativeName: '中文' },
}

export function isValidLocale(locale: string): locale is Locale {
  return locales.includes(locale as Locale)
}

const translations: Record<Locale, () => Promise<any>> = {
  en: async () => {
    const compositions = await import('../messages/compositions/en.json')
    const countries = await import('../messages/countries/en.json')
    const teams = await import('../messages/teams/en.json')
    const leagues = await import('../messages/leagues/en.json')
    const sports = await import('../messages/sports/en.json')

    return {
      compositions: { // 添加命名空间
        ...compositions.default,
      },
      countries: {
        ...countries.default,
      },
      teams: {
        ...teams.default,
      },
      leagues: {
        ...leagues.default,
      },
      sports: {
        ...sports.default,
      },
    }
  },
  zh: async () => {
    const compositions = await import('../messages/compositions/zh.json')
    const countries = await import('../messages/countries/zh.json')
    const teams = await import('../messages/teams/zh.json')
    const leagues = await import('../messages/leagues/zh.json')
    const sports = await import('../messages/sports/zh.json')

    return {
      compositions: {
        ...compositions.default,
      },
      countries: {
        ...countries.default,
      },
      teams: {
        ...teams.default,
      },
      leagues: {
        ...leagues.default,
      },
      sports: {
        ...sports.default, // 添加缺失的展开运算符
      },
    }
  },
}

async function loadTranslations(locale: Locale) {
  return translations[locale]()
}

export default getRequestConfig(async ({ requestLocale }) => {
  const locale = await requestLocale

  if (!locale || !isValidLocale(locale)) {
    notFound()
  }

  return {
    locale,
    messages: await loadTranslations(locale),
  }
})

export async function getTranslations(locale: Locale) {
  return loadTranslations(locale)
}
