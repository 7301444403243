import type { Sport } from './useSports'
import { useSportTranslation } from './translations/useSportTranslation'
import { useCountryTranslation } from './translations/useCountryTranslation'
import { useLeagueTranslation } from './translations/useLeagueTranslation'
import { useTeamTranslations } from './translations/useTeamTranslations'


interface UseTranslateSportsProps {
  sports: Sport[] | undefined
}

export function useTranslateSports({ sports }: UseTranslateSportsProps) {
  const { getSportTranslation } = useSportTranslation()
  const { getCountryTranslation } = useCountryTranslation()
  const { getLeagueTranslation } = useLeagueTranslation()
  const { getTeamTranslation } = useTeamTranslations()

  if (!sports?.length) {
    return { translatedSports: undefined }
  }

  const translatedSports = sports.map((sport) => {
    const translatedSport = {
      ...sport,
      name: getSportTranslation(sport.name, sport.id.toString()),
    }

    const translatedLeagues = sport.leagues.map((league) => {
      const translatedLeague = {
        ...league,
        name: getLeagueTranslation(
          league.name,
          league.slug,
          sport.id.toString(),
          league.countrySlug
        ),
        countryName: getCountryTranslation(league.countryName, league.countrySlug),
      }

      if (translatedLeague.games) {
        translatedLeague.games = translatedLeague.games.map(game => {
          const translatedParticipants = game.participants.map(participant => ({
            ...participant,
            name: getTeamTranslation(participant.name, sport.id.toString()),
          }))

          return {
            ...game,
            participants: translatedParticipants,
            title: translatedParticipants.map(p => p.name).join(' – '),
          }
        })
      }

      return translatedLeague
    })

    return {
      ...translatedSport,
      leagues: translatedLeagues,
    }
  })

  return { translatedSports }
}
