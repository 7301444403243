export const currencyUnitValues = [
  'USD',
  'CNY',
  'JPY',
  'HKD',
  'INR',
  'KRW',
  'usd',
  'cny',
  'jpy',
  'hkd',
  'inr',
  'krw',
] as const;

export type CurrencyUnit = (typeof currencyUnitValues)[number];
