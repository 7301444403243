export default {
  title: {
    en: 'Oops... something went wrong',
  },
  text: {
    en: 'Sorry, the page you are looking for doesn’t exist or has been removed.',
  },
  buttonTitle: {
    en: 'Back to top events',
  },
}
