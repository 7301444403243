export const translationUtils = {
  normalizeKey: (key: string) => key.replace(/\./g, '_'),

  // 可以添加其他共享的工具函数
  createTranslationKey: (
    sportId: string,
    countrySlug: string,
    name: string
  ) => `${sportId}_${countrySlug}_${name}`,
}
