import { useFormatter, useTranslations } from 'next-intl'

// 改名为 useGameDateTime
const useGameDateTime = (startDate: number) => {
  const format = useFormatter()
  const t = useTranslations('compositions.dateTime')
  const dateObj = new Date(startDate)
  const now = new Date()

  // 格式化时间
  const time = format.dateTime(dateObj, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  // 简单的日期比较
  const isToday = dateObj.toDateString() === now.toDateString()
  const isTomorrow = dateObj.toDateString() === new Date(now.setDate(now.getDate() + 1)).toDateString()

  let date: string

  if (isToday) {
    date = t('today')
  }
  else if (isTomorrow) {
    date = t('tomorrow')
  }
  else {
    date = format.dateTime(dateObj, {
      day: '2-digit',
      month: 'short',
    })
  }

  return { date, time }
}

export default useGameDateTime
