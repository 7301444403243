'use client'

import React from 'react'
import { useLocale } from 'next-intl'
import { useRouter, usePathname } from '@/i18n/routing'
import type { Locale } from '@/i18n/i18n'
import { locales, languageConfig } from '@/i18n/i18n'
import Dropdown from '@/components/inputs/Dropdown/Dropdown'


const LanguageSwitcher: React.FC = () => {
  const router = useRouter()
  const pathname = usePathname()
  const currentLocale = useLocale() as Locale

  const handleLanguageChange = (newLocale: Locale) => {
    router.push(pathname, { locale: newLocale })
  }

  const dropdownContent = (
    <div className="py-1 bg-gray-800 rounded-md shadow-lg">
      {
        locales.map((locale) => (
          <button
            key={locale}
            onClick={() => handleLanguageChange(locale)}
            className={
              `block w-full text-left px-3 py-1 text-sm ${
                locale === currentLocale
                  ? 'bg-gray-700 text-white'
                  : 'text-gray-300 hover:bg-gray-700'
              }`
            }
          >
            {languageConfig[locale].nativeName}
          </button>
        ))
      }
    </div>
  )

  return (
    <Dropdown
      content={dropdownContent}
      buttonClassName="px-3 py-1 bg-gray-800 text-white rounded-md text-sm font-medium hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
      contentClassName="mt-1"
      placement="bottomRight"
    >
      {
        ({ isOpened }) => (
          <div className="flex items-center justify-between min-w-[80px]">
            <span>{languageConfig[currentLocale].nativeName}</span>
            <svg
              className={`ml-2 h-4 w-4 transform ${isOpened ? 'rotate-180' : ''}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </div>
        )
      }
    </Dropdown>
  )
}

export default LanguageSwitcher

