export default {
  all: {
    en: 'All',
  },
  today: {
    en: 'Today',
  },
  tomorrow: {
    en: 'Tomorrow',
  },
  '1h': {
    en: '1h',
  },
  '3h': {
    en: '3h',
  },
  '6h': {
    en: '6h',
  },
}
